import React from 'react';
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout';
import Container from '../components/Container';
import Banner from '../components/Banner';
import { Link } from 'gatsby'

const NotFound = () => (
  <Layout>
    <Seo title="404 Error" />
    <Container>
      <Banner
        heading="404 Error"
        subtitle="Uh Oh"
        mainOrder="text-center"
        textAlign="center"
        content={`
          <p class="text-center lg:-mr-8">Looks like the page you are looking for doesn't exist.</p>
          `}
        / >
        <div className="text-center" style={{paddingBottom: '6rem', paddingTop: '1rem'}}>
    		<Link className="button lowercase" to={`/`}>Back to Home</Link>
    		</div>
    </Container>


  </Layout>
);

export default NotFound;
